import { getUserStorable } from '@/helpers/utils'

export default {
    LOGOUT_USER(state) {
        state.activeUser = null;
        localStorage.removeItem(state.config.localUserKey);
        location.replace(`${state.config.domain}/logout`);
    },
    TOGGLE_LOADER(state, val) {
        state.isLoading = val;
    },
    SET_AUTH_TOKEN(state, data){
        state.activeUser = {...data.user};
        const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
        const config = {
            token: data.accessToken,
            tokenExpirationDate: expirationTime.toISOString(),
            user: getUserStorable(data.user),
        }
        const encryptedValue = btoa(escape(JSON.stringify(config)));
        localStorage.setItem(state.config.localUserKey, encryptedValue);
    },
    UPDATE_USER_INFO(state, user) {
        if(!user.id){return};
        state.activeUser 
        ? Object.assign(state.activeUser, user)
        : state.activeUser = {...user};

        const get_localUser = localStorage.getItem(state.config.localUserKey);
        const storedData = JSON.parse(unescape(atob(get_localUser)));
        storedData.user = getUserStorable(user);
        localStorage.setItem(state.config.localUserKey, btoa(escape(JSON.stringify(storedData))));
    },
    SET_SUBSCRIPTION(state, data){
        state.subscription = data
    },
    UPDATE_NOTIFICATION(state, notification){
        state.activeUser.notification = {...notification};
    },
    SET_CONFIG(state, config){
        Object.assign(state.config, config)
    },
    SET_COUNTRY(state, country){
        state.country = {...country};
    },
    SET_INVOICES(state, invoices){
        state.invoices = [...invoices]
    },

    //********  CATALOG  *******//
    SET_TASKS(state, tasks){
        state.catalog.tasks = [...tasks]
    },
    SET_PLANS(state, plans){
        state.catalog.plans = [...plans]
    },
    SET_ATTRIBUTES(state, attributes){
        state.catalog.attributes = [...attributes]
    },

}
