import countries from "./db/countries"
import env from '../env'
import { getUserData } from '@/helpers/utils'

export default {
    isLoading: false,
    activeUser: getUserData() || null,
    config:{
        ...env,
    },
    catalog:{
        tasks:[],
        plans:[],
        attributes: []
    },
    subscription: null,
    country: {},
    countries: countries,
}
